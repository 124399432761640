import backgroundResources from "../assets/images/bg-events.png";
import cardSoon from "../assets/images/card-soon.png";
import plant2022 from "../assets/images/plant2022.jpg";
import refrescante from '../assets/images/refrescante.gif'
import {Link} from "react-router-dom";
import React from "react";

export default function Eventos() {
    return (
        <div className={Eventos}>
            <section id='eventos' className="py-40" style={{
                backgroundImage: `url(${backgroundResources})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="container mx-auto my-20">
                    <p style={{
                        fontSize: '70px',
                        fontWeight: 'bold',
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        textTransform: 'uppercase'
                    }}>Eventos</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                        {/*
                            <a href="https://eventos.iglesialab.com/evento?id=refrescante-conferencia-nacional-santiago"
                               rel="noopener noreferrer" target="_blank">
                                <img className="object-cover w-full md:h-auto" src={refrescante} alt=""/>
                                <div className="p-8 bg-white border rounded shadow-sm">
                                    <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                        <p className="transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                                           aria-label="Category">
                                            La Viña Chile
                                        </p>
                                        <span className="text-gray-600">21-22 Octubre 2022</span>
                                    </p>
                                    <p aria-label="Article" title="Why i love C++"
                                       className="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">
                                        Conferencia Nacional - Santiago
                                    </p>
                                    <p className="mb-5 text-gray-700">
                                        Haz click aquí y asegura tus entradas. Cupos muy limitados
                                    </p>
                                </div>
                            </a>
                        */}
                        <a>
                            <img className="object-cover w-full md:h-auto" src={cardSoon} alt=""/>
                            <div className="p-8 bg-white border rounded shadow-sm">
                                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                    <p className="transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                                       aria-label="Category">
                                        Pronto
                                    </p>
                                    <span className="text-gray-600">2023</span>
                                </p>
                                <p aria-label="Article" title="Why i love C++"
                                   className="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    Iglesias La Viña Chile
                                </p>
                                <p className="mb-5 text-gray-700">
                                    Pronto más Información.
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}