import React from 'react';
import ReactDOM from "react-dom/client"

import App from './App';

//CSS
import './index.css';
import 'animate.css/animate.min.css'
import 'flowbite';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);