import backgroundResources from "../assets/images/bg-churches.png";
import bk1 from "../assets/images/bk1.png";
import bk2 from "../assets/images/bk2.png";
import bk3 from "../assets/images/bk3.png";
import bk4 from "../assets/images/bk4.png";
import bk5 from "../assets/images/bk5.png";
import bk6 from "../assets/images/bk6.png";
import bk7 from "../assets/images/bk7.png";
import bk8 from "../assets/images/bk8.png";
import bk9 from "../assets/images/bk9.png";
import bk10 from "../assets/images/bk10.png";
import bk11 from "../assets/images/bk11.png";
import prayer from "../assets/downloads/57654_LordsPrayer_021017.pdf"
import values from "../assets/downloads/Declaraciones-Teológicas-y-Valóricas.pdf"
import soul from "../assets/downloads/How-Is-Your-Soul-Booklet_Spanish.pdf"
import reflections from "../assets/downloads/Reflexiones-de-John-Wimber.pdf"
import kingdom from "../assets/downloads/SPANISH-What-Is-the-Kingdom.pdf"
import venter from "../assets/downloads/venter-el-big-bang-de-la-nueva-creacion.pdf"

export default function Libros() {
    return (
        <div className={Libros}>
            <section id='nosotros' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-5">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>Libros</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <div className="container mx-auto">
                <div className="flex grid items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-4 py-12">
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://www.openbooks.cl/el-adn-de-la-vina-vineyard-resources">
                            <img className="p-8 rounded-t-lg" src={bk1} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    El ADN de la Viña
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Vineyard Resources
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">$6.000</span>
                                <a href="https://www.openbooks.cl/el-adn-de-la-vina-vineyard-resources" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Comprar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://www.openbooks.cl/desde-el-santuario-a-las-calles-charles-bello-brian-blount">
                            <img className="p-8 rounded-t-lg" src={bk2} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Del el Santuario a las Calles
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Brian Blount
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">$9.000</span>
                                <a href="https://www.openbooks.cl/desde-el-santuario-a-las-calles-charles-bello-brian-blount" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Comprar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://www.openbooks.cl/detras-del-velo-roger-cunnigham">
                            <img className="p-8 rounded-t-lg" src={bk3} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Detrás del Velo
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Roger Cunningham
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">$9.000</span>
                                <a href="https://www.openbooks.cl/detras-del-velo-roger-cunnigham" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Comprar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://www.openbooks.cl/haciendo-iglesia-alexander-venter">
                            <img className="p-8 rounded-t-lg" src={bk4} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Haciendo Iglesia
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Alexander Venter
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">$11.000</span>
                                <a href="https://www.openbooks.cl/haciendo-iglesia-alexander-venter" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Comprar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href="https://www.openbooks.cl/diferentes-pero-iguales-derek-morphew">
                            <img className="p-8 rounded-t-lg" src={bk5} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Diferentes pero Iguales
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Derek Morphew
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">$9.000</span>
                                <a href="https://www.openbooks.cl/el-adn-de-la-vina-vineyard-resources" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Comprar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={venter}>
                            <img className="p-8 rounded-t-lg" src={bk6} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    El Big Bang de la Creación
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Alexander Venter
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a target="_blank" href={venter} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={soul}>
                            <img className="p-8 rounded-t-lg" src={bk7} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    ¿Cómo Está tu Alma?
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Vineyard Resources
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a target="_blank" href={soul} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={prayer}>
                            <img className="p-8 rounded-t-lg" src={bk8} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    El Padre Nuestro
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Vineyard Resources
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a target="_blank" href={prayer} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={kingdom}>
                            <img className="p-8 rounded-t-lg" src={bk9} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    ¿Qué es el Reino?
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Vineyard Resources
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a target="_blank" href={kingdom} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={values}>
                            <img className="p-8 rounded-t-lg" src={bk10} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Declaraciones de La Viña
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    Iglesias la Viña Chile
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a target="_blank" href={values} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-sm mx-1 my-1 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <a href={reflections}>
                            <img className="p-8 rounded-t-lg" src={bk11} alt="product image"/>
                        </a>
                        <div className="px-5 pb-5">
                            <a href="#">
                                <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    Reflexiones
                                </h5>
                                <h5 className="text-xl font-light tracking-tight text-gray-900 dark:text-white">
                                    John Wimber
                                </h5>
                            </a>
                            <div className="flex justify-between items-center">
                                <span className="text-3xl font-bold text-gray-900 dark:text-white">Gratis</span>
                                <a href={reflections} target="_blank" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}