import backgroundResources from "../assets/images/bg-youth.png";
import logo from "../assets/images/logo-youth.png";

export default function Jovenes() {
    return (
        <div className="Jovenes" style={{backgroundColor: '#000'}}>
            <section id='jovenes' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <div className="container mx-auto my-20">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>Jóvenes</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="elfsight-app-57238511-0934-467f-bdd8-1cf8c4ae000f"></div>
            </section>
        </div>
    )
}