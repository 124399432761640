import backgroundResources from "../assets/images/bg-plant.png";
import cn1 from "../assets/images/conf-1.png";
import cn2 from "../assets/images/conf-2.png";
import cn3 from "../assets/images/conf-3.png";
import cn4 from "../assets/images/conf-4.png";
import cn5 from "../assets/images/conf-5.png";
import cn6 from "../assets/images/conf-6.png";
import cn7 from "../assets/images/conf-7.png";
import cn8 from "../assets/images/conf-8.png";
import cn9 from "../assets/images/conf-9.png";
import cn10 from "../assets/images/conf-10.jpg";
import {Link} from "react-router-dom";

export default function Videos() {
    return (
        <div className={Videos}>
            <section id='videos' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-20">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>
                        Conferencias
                    </p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-5 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/refrescante" aria-label="Article">
                                <img src={cn10} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Santiago - 2022
                                </p>
                                <Link to="/conferencias/v/incontenible" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Refrescante
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/incontenible" aria-label="Article">
                                <img src={cn9} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Santiago - 2022
                                </p>
                                <Link to="/conferencias/v/incontenible" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Incontenible
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/ardiendo" aria-label="Article">
                                <img src={cn8} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Santiago - 2021
                                </p>
                                <Link to="/conferencias/v/ardiendo" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Ardiendo
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/volviendo-a-lo-esencial" aria-label="Article">
                                <img src={cn7} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Online - 2021
                                </p>
                                <Link to="/conferencias/v/volviendo-a-lo-esencial" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Volviendo a lo Esencial
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/epico" aria-label="Article">
                                <img src={cn6} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Online - 2020
                                </p>
                                <Link to="/conferencias/v/epico" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Épico
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/siembra" aria-label="Article">
                                <img src={cn5} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Online - 2020
                                </p>
                                <Link to="/conferencias/v/siembra" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Siembra
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/ven-espiritu-santo" aria-label="Article">
                                <img src={cn4} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Online - 2020
                                </p>
                                <Link to="/" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Ven Espíritu Santo
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/alba" aria-label="Article">
                                <img src={cn3} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Online - 2020
                                </p>
                                <Link to="/conferencias/v/alba" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Alba
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/raices-de-la-vina" aria-label="Article">
                                <img src={cn2} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Santiago - 2015
                                </p>
                                <Link to="/conferencias/v/raices-de-la-vina" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Raíces de la Viña
                                    </p>
                                </Link>
                            </div>
                        </div>
                        <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                            <Link to="/conferencias/v/senales-maravillas-y-crecimiento-de-la-iglesia" aria-label="Article">
                                <img src={cn1} className="object-cover w-full h-64 rounded" alt=""/>
                            </Link>
                            <div className="py-5">
                                <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                                    Anaheim, CA - 1980
                                </p>
                                <Link to="/conferencias/v/senales-maravillas-y-crecimiento-de-la-iglesia" aria-label="Article" className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700">
                                    <p className="text-2xl font-bold leading-5">
                                        Señales, Maravillas y Crecimiento de la Iglesia
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}