import React from 'react';
import familia from '../assets/images/familia.png'
import refrescante from '../assets/images/refrescante.gif'
import backgroundResources from '../assets/images/ResourcesBg.png'
import BgLogo from '../assets/images/bg-logo.png'
import card1 from '../assets/images/card-1.png'
import card2 from '../assets/images/card-2.png'
import card3 from '../assets/images/card-3.png'
import card4 from '../assets/images/card-4.png'
import card5 from '../assets/images/card-5.png'
import card6 from '../assets/images/card-6.png'
import {Link} from "react-router-dom";

export default function Home() {

    function handleSelectChange(event) {
        window.open(event.target.value, '_blank');
    }

    return (
        <div>
            {/*
            <section>
                <img src={refrescante} alt="Refrescante - Conferencia Nacional Iglesias La Viña Chile"/>
            </section>
            <section>
                <div
                    className="px-4 pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
                    <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center md:text-center">
                    <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-gray sm:text-4xl sm:leading-none">
                        ¡Reserva tu entrada!
                    </h2>
                    </div>
                </div>
            </section>
            <section>
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-8 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
                        <a href="https://eventos.iglesialab.com/evento?id=refrescante-conferencia-nacional-los-angeles" rel="noopener noreferrer" target="_blank">
                            <img className="object-cover w-full md:h-auto" src={refrescante} alt=""/>
                            <div className="p-8 bg-white border rounded shadow-sm">
                                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                    <p className="transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                                       aria-label="Category">
                                        La Viña Chile
                                    </p>
                                    <span className="text-gray-600">14-15 Octubre 2022</span>
                                </p>
                                <p aria-label="Article" title="Why i love C++"
                                   className="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    Conferencia Nacional - Los Ángeles
                                </p>
                                <p className="mb-5 text-gray-700">
                                    Haz click aquí y asegura tus entradas. Cupos muy limitados
                                </p>
                            </div>
                        </a>
                        <a href="https://eventos.iglesialab.com/evento?id=refrescante-conferencia-nacional-santiago" rel="noopener noreferrer" target="_blank">
                            <img className="object-cover w-full md:h-auto" src={refrescante} alt=""/>
                            <div className="p-8 bg-white border rounded shadow-sm">
                                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                    <p className="transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
                                       aria-label="Category">
                                        La Viña Chile
                                    </p>
                                    <span className="text-gray-600">21-22 Octubre 2022</span>
                                </p>
                                <p aria-label="Article" title="Why i love C++"
                                   className="inline-block mb-3 text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-deep-purple-accent-400">
                                    Conferencia Nacional - Santiago
                                </p>
                                <p className="mb-5 text-gray-700">
                                    Haz click aquí y asegura tus entradas. Cupos muy limitados
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
            */}
            <section className="bg-black z-0 relative flex items-center justify-center h-screen overflow-hidden">
                <div className="relative z-10 p-5 text-2xl text-white rounded-xl">
                    <img src={familia} alt="Familia - Iglesias La Viña Chile"/>
                </div>
                <video autoPlay loop muted className="opacity-50 absolute z-0 w-auto min-w-full min-h-full max-w-none">
                    <source src="https://player.vimeo.com/progressive_redirect/playback/700577423/rendition/720p?loc=external&signature=88ab35d0b6ff00c69fbad0b4aed746f7281551d0096ae9d2c36e12a4dc24f198" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </section>
            <section id='church-finder'>
                <div className="relative bg-zinc-800">
                    <div className="absolute inset-x-0 bottom-0">
                        <svg viewBox="0 0 224 12" fill="currentColor" className="w-full -mb-1 text-white" preserveAspectRatio="none">
                            <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"/>
                        </svg>
                    </div>
                    <div
                        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
                            <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                                Encuentra una viña
                            </h2>
                            <p className="mb-6 text-base font-thin tracking-wide text-gray-300 md:text-lg">
                                Tenemos un llamado único a vivir el Reino, el ministerio del Espíritu Santo y la edificación de iglesias. Dios está haciendo grandes cosas y creemos que esto es solo el comienzo. Conoce nuestras iglesias que tenemos al rededor de Chile.
                            </p>
                            <form onChange={handleSelectChange} className="flex flex-col items-center w-full mb-4 md:flex-row md:px-16">
                                <select id="countries" className="flex-grow w-full h-12 px-4 mb-3 text-white transition duration-200 bg-transparent border-2 border-gray-400 rounded appearance-none md:mr-2 md:mb-0 focus:border-orange-400 focus:outline-none focus:shadow-outline">
                                    <option>Selecciona una Iglesia</option>
                                    <option value="https://www.instagram.com/vinalaserena/s">Viña La Serena</option>
                                    <option value="https://www.unionchurch.cl/escritorio">Viña Union Church</option>
                                    <option value="https://www.instagram.com/comunidadvinapuerto/">Viña Puerto</option>
                                    <option value="http://vinadelpadre.cl/">Viña Del Padre</option>
                                    <option value="https://ivlc.cl">Viña Las Condes</option>
                                    <option value="http://vinaprovidencia.cl/">Viña Providencia</option>
                                    <option value="https://agape.cl/">Viña Ágape</option>
                                    <option value="https://lavinacordillera.cl/">Viña Cordillera</option>
                                    <option value="https://es-la.facebook.com/iVinaSanMiguel">Viña San Miguel</option>
                                    <option value="http://ivmacul.org/">Viña Macul</option>
                                    <option value="https://es-la.facebook.com/Lavinareencuentro/">Viña Reencuentro</option>
                                    <option value="https://www.vlf.cl/">Viña La Florida</option>
                                    <option value="https://vinacentro.cl/">Viña Centro</option>
                                    <option value="https://vinamaipu.cl/">Viña Maipú</option>
                                    <option value="https://www.instagram.com/iglesiavinasanbernardo/">Viña San Bernardo</option>
                                    <option value="https://es-la.facebook.com/Iglesiavinalinares/">Viña Linares</option>
                                    <option value="https://es-la.facebook.com/lavina.losangeles/">Viña Los Ángeles</option>
                                    <option value="https://vinaconce.cl/">Viña Concepción</option>
                                    <option value="https://www.instagram.com/vinalomas/?hl=es">Viña Lomas</option>
                                    <option value="https://es-la.facebook.com/iglesiavinabiobio/">Viña Chiguayante</option>
                                    <option value="https://www.instagram.com/iglesia.vina.pucon/?hl=es">Viña Pucón</option>
                                    <option value="http://vinavaldivia.cl/">Viña Valdivia</option>
                                    <option value="https://familiavinaosorno.cl/">Viña Osorno</option>
                                    <option value="https://www.vinapm.cl/">Viña Puerto Montt</option>
                                </select>
                                <button type="submit" className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded md:w-auto focus:shadow-outline focus:outline-none">
                                    Conocer
                                </button>
                            </form>
                            <p className="max-w-md mb-10 text-xs font-thin tracking-wide text-gray-500 sm:text-sm sm:mx-auto md:mb-16">
                                Selecciona una de nuestras iglesias y luego haz click en conocer para tener más información.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id='about-us'>
                <div className="container sm:mx-10 md:mx-auto my-10 py-20">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 grid-rows-1">
                        <Link to="nosotros" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                src={card5} alt=""/>
                                <div className="flex flex-col justify-between p-4 leading-normal">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Nosotros</h5>
                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                        En esta sección podrás conocer mucho más acerca de nuestra historia, nuestro increíble equipo y nuestros valores.
                                    </p>
                                </div>
                        </Link>
                        <Link to="eventos" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                src={card6} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Eventos</h5>
                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Conéctate con lo que Dios está haciendo dentro del movimiento. Aquí encontrarás diversas instancias para sumarte.</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            <section id='conferences' className="py-40" style={{ backgroundImage: `url(${backgroundResources})` }}>
                <div className="container mx-auto my-20">
                    <div className="p-6  max-w-sm rounded-lg bg-white border border-gray-200 shadow-md dark:border-gray-700">
                        <Link to="recursos">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Conferencias, Libros y Adoración</h5>
                        </Link>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Ingresa a nuestra sección de recursos donde podrás encontrar nuestras conferencias, noches de adoración, libros y múltiples recursos para conectarte con La Viña Chile.</p>
                        <Link to="recursos" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-orange-300 rounded-lg hover:bg-orange-400 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Ver Recursos
                            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
            <section id='about-us bg-no-repeat bg-cover' style={{ backgroundImage: `url(${BgLogo})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-10 py-10">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 grid-rows-1">
                        <Link to="jovenes" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                 src={card3} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Jóvenes Viña Chile</h5>
                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                    Los Jóvenes Viña Chile son un área clave dentro de nuestro movimiento. Todo avivamiento en la historia comenzó en el corazón de jóvenes.
                                </p>
                            </div>
                        </Link>
                        <Link to="mas-vinas" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                 src={card4} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Más Viñas</h5>
                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">El Equipo Más Viñas tiene la misión de potenciar y acompañar el nacimiento de más Iglesias Viña en Chile.</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="container mx-auto my-10 py-10">
                    <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 grid-rows-1">
                        <Link to="formacion" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                 src={card1} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Instituto Viña Chile</h5>
                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">La misión del Instituto Viña Chile es equipar y enseñar a quienes persiguen apasionadamente el liderazgo, pastorado o plantación de Iglesias.</p>
                            </div>
                        </Link>
                        <Link to="adoracion" className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                 src={card2} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adoración Viña Chile</h5>
                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Conéctate con la adoración de cada una de las iglesias viñas del país y celebremos juntos lo que Dios está haciendo en Chile.</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            <section id='phrase'>

            </section>
        </div>
    )
}