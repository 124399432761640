import backgroundResources from "../assets/images/bg-us.png";
import ourStoryVideo from "../assets/images/ourstoryvideo.jpeg";
import staff1 from "../assets/images/staff1.jpg"
import staff2 from "../assets/images/staff2.jpg"
import staff3 from "../assets/images/staff3.jpg"
import staff4 from "../assets/images/staff4.jpg"

export default function Nosotros() {
    return (
        <div className={Nosotros}>
            <section id='nosotros' className="py-40" style={{backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <div className="container mx-auto my-20">
                    <p style={{
                        fontSize: '70px',
                        fontWeight: 'bold',
                        color: 'white',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        textTransform: 'uppercase'
                    }}>Nosotros</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-900 uppercase rounded-full bg-teal-accent-400">
                                Video
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                          <span className="relative inline-block">
                            <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                              <defs>
                                <pattern id="679d5905-e08c-4b91-a66c-84aefbb9d2f5" x="0" y="0" width=".135" height=".30">
                                  <circle cx="1" cy="1" r=".7"/>
                                </pattern>
                              </defs>
                              <rect fill="url(#679d5905-e08c-4b91-a66c-84aefbb9d2f5)" width="52" height="24"/>
                            </svg>
                          </span>
                            Conoce nuestra historia
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg text-justify">
                            Hoy honramos nuestra historia pero no vivimos en ella. Queremos ir más allá de nuestra historia hacia nuestro destino. Queremos que nuestra propia historia de Dios se mueva en nuestra generación y en las naciones, que nuestra propia historia del reino avance a través de nuestros corazones y manos, que nuestra propia historia forme su historia entre nosotros.
                        </p>
                    </div>
                    <div className="mx-auto lg:max-w-2xl">
                        <div className="relative w-full transition-shadow duration-300 hover:shadow-xl">
                            <img className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
                                 src={ourStoryVideo}
                                 alt=""/>
                            <a href="https://youtu.be/OKZwgmTc5Cc"
                               aria-label="Play Video"
                               className="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
                                <div
                                    className="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
                                    <svg className="w-10 text-gray-900"
                                         fill="currentColor"
                                         viewBox="0 0 24 24">
                                        <path
                                            d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z"/>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="mx-auto lg:max-w-xl sm:text-center">
                        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-900 uppercase rounded-full bg-teal-accent-400">
                            Conócenos
                        </p>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            Nuestro Equipo
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                            El trabajo del Equipo Nacional es equipar, alentar y empoderar aún más a las iglesias locales en su misión del Reino.
                        </p>
                    </div>
                    <div className="relative flex flex-col pb-16 lg:pt-0 lg:flex-col lg:pb-0">
                        <div className="inset-y-0 right-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
                            <img
                                className="w-full h-full rounded shadow-lg lg:rounded-none lg:shadow-none sm:h-96 mt-40 mb-20"
                                src={staff1}
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
                            <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
                                <div className="max-w-xl mb-6">
                                    <div>
                                        <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-orange-900 uppercase rounded-full bg-teal-accent-400">
                                        Directores Nacionales
                                        </p>
                                    </div>
                                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                        Claudio Caro & Priscila Vásquez
                                    </h2>
                                        <p className="text-base text-gray-700 md:text-lg text-justify">
                                        La estructura de liderazgo de la Iglesia La Viña Chile está diseñada para servir a nuestras iglesias locales en todo el país, para ayudarlas a convertirse en todo lo que Dios las ha diseñado para ser.
                                        </p><br/>
                                        <p className="text-base text-gray-700 md:text-lg text-justify">
                                        En el año 2018, tuvimos la oportunidad de nombrar a nuestros primeros directores nacionales, Claudio y Priscila Caro, quienes junto a un equipo coordinador lideran el caminar de la Iglesia La Viña Chile por los próximos años.
                                        </p><br/>
                                        <p className="text-base text-gray-700 md:text-lg text-justify">
                                        Nuestro enfoque desarrollado de liderazgo nos mantiene ágil y flexibles, buscando llegar a un mundo que cambia con un Evangelio vivo.
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg">
                        <div>
                            <div className="relative pb-56 mb-4 rounded shadow lg:pb-64">
                                <img
                                    className="absolute object-cover w-full h-full rounded"
                                    src={staff2}
                                    alt="Person"
                                />
                            </div>
                            <div className="flex flex-col sm:text-center">
                                <p className="text-lg font-bold">Javier Menéndez &  Andrea Vallette</p>
                                <p className="mb-5 text-xs text-gray-800">Área de Formación</p>
                            </div>
                        </div>
                        <div>
                            <div className="relative pb-56 mb-4 rounded shadow lg:pb-64">
                                <img
                                    className="absolute object-cover w-full h-full rounded"
                                    src={staff3}
                                    alt="Person"
                                />
                            </div>
                            <div className="flex flex-col sm:text-center">
                                <p className="text-lg font-bold">Esteban Clavijo & Gladys Inzunza</p>
                                <p className="mb-5 text-xs text-gray-800">Área de Finanzas</p>
                            </div>
                        </div>
                        <div>
                            <div className="relative pb-56 mb-4 rounded shadow lg:pb-64">
                                <img className="absolute object-cover w-full h-full rounded"
                                     src={staff4}
                                     alt="Person"/>
                            </div>
                            <div className="flex flex-col sm:text-center">
                                <p className="text-lg font-bold">Jamie Van Der Westhuizen & Sylvia Vergara</p>
                                <p className="mb-5 text-xs text-gray-800">Área de Plantación</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex flex-col lg:flex-row">
                        <div className="max-w-xl pr-16 mx-auto mb-10">
                            <h5 className="mb-6 text-3xl font-extrabold leading-none">
                                Nuestros Valores
                            </h5>
                            <p className="mb-6 text-gray-900">
                                Cada hogar es construido sobre un fundamento. La Iglesia Viña Chile como movimiento está construido sobre el fundamento de nuestra teología (Qué pensamos de Dios) y nuestros valores (Conceptos y prácticas que son prioridad para nuestra vida como iglesia).
                            </p>
                        </div>
                        <div className="grid gap-5 row-gap-5 sm:grid-cols-2">
                            <div className="max-w-md">
                                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-orange-300">
                                    <svg
                                        className="w-12 h-12"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">Valoramos Vivir el Reino de Dios</h6>
                                <p className="text-sm text-gray-700 text-justify">
                                    Creemos que el Reino de Dios fue instaurado en la tierra con la venida de Jesús y que hoy día sigue siendo una realidad tangible en nuestro medio. Por esto creemos en las manifestaciones del poder y la autoridad de Dios, cada vez que su Reino viene sobre nosotros, cosas magnificas ocurren. Valoramos la búsqueda y la experiencia de ver a Dios irrumpir con milagros y señales tal como están prometidas para los que creen y son sus hijos.
                                </p>
                            </div>
                            <div className="max-w-md">
                                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-orange-300">
                                    <svg
                                        className="w-12 h-12 text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">Valoramos la Intimidad con Dios</h6>
                                <p className="text-sm text-gray-700 text-justify">
                                    Dios nos creó para que viviéramos una vida de relación íntima y real con Él, esta es la clave para una vida cristiana abundante, Dios es la fuente de vida y el alimento que necesitamos. Por esto siempre nos estarás escuchando hablar de intimidad con Dios, porque todo comienza y termina ahí, en adoración, oración, reflexión y sumisión a nuestro Padre.
                                </p>
                            </div>
                            <div className="max-w-md">
                                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-orange-300">
                                    <svg
                                        className="w-12 h-12 text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">Valoramos las Relaciones Auténticas</h6>
                                <p className="text-sm text-gray-700 text-justify">
                                    Jesús oró para que fuéramos uno, como Él y el Padre lo eran (Juan 17:21), creemos fuertemente que los miembros de nuestra comunidad deben experimentar entre ellos relaciones de amor, gracia, honestidad y perdón. La invitación es a conectarnos con personas haciéndonos vulnerables y cuidándonos unos a los otros, animándonos y fortaleciéndonos mutuamente en el ejercicio de la fe y la vida cristiana.
                                </p>
                            </div>
                            <div className="max-w-md">
                                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-orange-300">
                                    <svg
                                        className="w-12 h-12 text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">Valoramos la Misericordia y la Gracia</h6>
                                <p className="text-sm text-gray-700 text-justify">
                                    El primer y más grande de todos los mandamientos es amar al Señor con todo el corazón, con toda el ama, con todas las fuerzas y con todo el entendimiento. Nuestro origen es Dios, nuestra vida es Dios y nuestro destino final es Dios. Queremos ser una iglesia que refleje el amor de Dios de una manera práctica y genuina, por eso incentivamos a la familia de la Viña Chile a ser los pies y las manos del Padre para aquellos que están en necesidad espiritual y/o material.
                                </p>
                            </div>
                            <div className="max-w-md">
                                <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-orange-300">
                                    <svg
                                        className="w-12 h-12 text-deep-purple-accent-400"
                                        stroke="currentColor"
                                        viewBox="0 0 52 52"
                                    >
                                        <polygon
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            fill="none"
                                            points="29 13 14 29 25 29 23 39 38 23 27 23"
                                        />
                                    </svg>
                                </div>
                                <h6 className="mb-2 font-semibold leading-5">Valoramos la Palabra de Dios</h6>
                                <p className="text-sm text-gray-700 text-justify">
                                    Creemos que la Biblia (de Génesis a Apocalípsis) fue inspirada por Dios y por lo tanto toda experiencia toma sentido y verdad a la luz de la palabra de Dios. Es ella, a través de la revelación del Espíritu Santo, la que nos guía a conocer verdaderamente quien es Dios y como debemos vivir nuestras vidas bajo su Señorío. Creemos en una palabra puesta en práctica, partiendo por nuestras propias vidas.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}