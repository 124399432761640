import backgroundResources from "../assets/images/bg-worship.png";

export default function Adoracion() {
    return (
        <div className="Adoracion">
            <section id='adoracion' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-20">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>Adoración</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div
                    className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
                    <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                                    <span className="relative inline-block">
                                        <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                                <defs>
                                                    <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                                                        <circle cx="1" cy="1" r=".7"/>
                                                    </pattern>
                                                </defs>
                                            <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"/>
                                        </svg>
                                    </span>
                                    Adoración
                                </h2>
                                <p className="text-justify text-base text-gray-700 md:text-lg">
                                    La adoración no solo tiene que ver con música, sino que con una postura de corazón, un espacio donde Dios se deleita y se manifiesta con su presencia. Creemos que es en un ambiente donde el Espíritu Santo habita y por ende el Reino de Dios se manifiesta con todas sus cualidades.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto">
                    <div className="flex grid items-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl-grid-cols-3 py-12">
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Ven</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Las Condes - 2019</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/us/album/ven/1481690452?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Más Allá</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Las Condes - 2019</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/m%C3%A1s-all%C3%A1/1469261863?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Inagotable</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Ágape - 2017</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/inagotable/1431069169?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Lluvia Temprana</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Ágape - 2014</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/lluvia-temprana/1248966667?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Nos Despiertas a Vivir</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Las Condes - 2013</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/nos-despiertas-vivir-adoraci%C3%B3n-en-vivo-vi%C3%B1a-las-condes/1011850909?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Rendido a Sus Pies</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Chile - 2004</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/rendido-a-sus-pies/305427852?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">Tuyo Seré</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Chile - 2004</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/tuyo-ser%C3%A9/1422970920?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">El Río de Dios</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Chile - 2001</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/cl/album/el-r%C3%ADo-de-dios/1435027046?app=music"></iframe>
                        </div>
                        <div className="max-w-sm mx-1 my-10 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <h5 className="my-3 text-center text-xl font-semibold tracking-tight text-gray-900">10 Años de La Viña Chile</h5>
                            <h4 className="my-3 text-center text-md font-regular tracking-tight text-gray-700">Iglesia La Viña Chile - 1991</h4>
                            <iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
                                    style={{width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent'}}
                                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                                    src="https://embed.music.apple.com/us/album/10-a%C3%B1os-1991-2001/1528227763"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}