import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './App.css';

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Nosotros from './pages/Nosotros'
import Eventos from './pages/Eventos'
import Adoracion from './pages/Adoracion'
import Formacion from './pages/Formacion'
import Jovenes from './pages/Jovenes'
import Plantacion from './pages/Plantacion'
import Iglesia from './singles/Iglesia'
import Recursos from './pages/Recursos'
import Error from './pages/Error'
import Libros from "./pages/Libros";
import Videos from "./pages/Videos";
import Conferencia from "./singles/Conferencia";

function App() {
  return (
      <BrowserRouter>

          <Navbar/>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/nosotros" element={<Nosotros/>}/>
            <Route path="/eventos" element={<Eventos/>}/>
            <Route path="/adoracion" element={<Adoracion/>}/>
            <Route path="/formacion" element={<Formacion/>}/>
            <Route path="/jovenes" element={<Jovenes/>}/>
            <Route path="/mas-vinas" element={<Plantacion/>}/>
            <Route path="/recursos" element={<Recursos/>}/>
            <Route path="/conferencias" element={<Videos/>}/>
            <Route path="/libros" element={<Libros/>}/>

            <Route path="/iglesias/i/:slug" element={<Iglesia/>}/>
            <Route path="/conferencias/v/:slug" element={<Conferencia/>}/>
            <Route path="*" element={<Error/>}/>
          </Routes>

        <Footer/>

      </BrowserRouter>
  );
}

export default App;
