import {useParams} from "react-router-dom";

export default function Iglesia() {

    const {slug} = useParams();

    const iglesias = [
        {
            chId: 1,
            chName: 'Cómo Reconocer la voz de Dios',
            chSlug: 'como-reconocer-la-voz-de-dios',
            chsCity: 'como-reconocer-la-voz-de-dios',
            chsRegion: 1,
            chAvenue: '29990',
            chSeniors: 'Francisco Tapia',
            chSeniorsPicture: 'Francisco Tapia',
            chLogo: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
            chImageLogo: '8 Semanas',
            chMeetings: 'https://truthtotable.com/wp-content/uploads/2016/11/sf-featured.png',
            chUrl: '#',
            description: 'Soul Food is a Bible study journey through four meals in the Bible and how they reveal the four different soul ‘hungers’ we each have.  Join us for this study to see what vibrant living looks like on a daily basis.'
        },
    ]

    return (
        <div className="Iglesia">
            <h1>Iglesia {slug}</h1>
        </div>
    )
}