import React, { useEffect } from "react";
import backgroundResources from "../assets/images/bg-plant.png";

export default function Plantacion() {
    useEffect(() => {
        window.location.href = "https://masvinas.cl";
    }, []);
    return (
        <div className={Plantacion}>
            <section id='plantacion' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-20">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>Más Viñas</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="mt-10">
                    <div className="bg-white-100">
                        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
                            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                                <div>
                                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                                        Plantaciones y Adopciones
                                    </p>
                                </div>
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                                  <span className="relative inline-block">
                                    <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-400 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                                      <defs>
                                        <pattern id="dc223fcc-6d72-4ebc-b4ef-abe121034d6e" x="0" y="0" width=".135" height=".30">
                                          <circle cx="1" cy="1" r=".7" />
                                        </pattern>
                                      </defs>
                                      <rect fill="url(#dc223fcc-6d72-4ebc-b4ef-abe121034d6e)" width="52" height="24"/>
                                    </svg>
                                  </span>
                                    Conociendo Más Viñas
                                </h2>
                                <p className="text-justify text-base text-gray-700 md:text-lg">
                                    El Equipo Más Viñas es una comisión encomendada por la Dirección Nacional de las Iglesias Viña Chile
                                    con el fin de colaborar en el proceso de plantación de iglesias.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
                    <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
                        <div className="sm:text-center">
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                                <svg className="w-12 h-12 text-deep-purple-accent-400 sm:w-20 sm:h-20" stroke="currentColor" viewBox="0 0 52 52">
                                    <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"/>
                                </svg>
                            </div>
                            <h6 className="mb-2 font-semibold leading-5">Difusión</h6>
                            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                                Desarrollamos un plan para sembrar la visión de la plantación de una iglesia para los próximos 5 años.
                            </p>
                        </div>
                        <div className="sm:text-center">
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                                <svg className="w-12 h-12 text-deep-purple-accent-400 sm:w-20 sm:h-20" stroke="currentColor" viewBox="0 0 52 52">
                                    <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"/>
                                </svg>
                            </div>
                            <h6 className="mb-2 font-semibold leading-5">Seguimiento</h6>
                            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                                Apoyamos las iniciativas de pastores a plantar nuevas iglesias, desde sus inicios.
                            </p>
                        </div>
                        <div className="sm:text-center">
                            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                                <svg className="w-12 h-12 text-deep-purple-accent-400 sm:w-20 sm:h-20" stroke="currentColor" viewBox="0 0 52 52">
                                    <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"/>
                                </svg>
                            </div>
                            <h6 className="mb-2 font-semibold leading-5">Capacitación</h6>
                            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                                Proveemos material para plantadores y sus equipos e instancias de capacitación.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}