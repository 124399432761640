import backgroundResources from "../assets/images/bg-churches.png";
import c1 from "../assets/images/conference-card.png"
import c2 from "../assets/images/book-card.png"
import c3 from "../assets/images/worship-card.png"
import {Link} from "react-router-dom";

export default function Recursos() {
    return (
        <div>
            <section id='nosotros' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-5">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>   Recursos</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="grid gap-8 lg:grid-cols-3 sm:max-w-sm sm:mx-auto lg:max-w-full">
                        <a href="/conferencias">
                            <div className="text-center py-10" style={{ backgroundImage: `url(${c1})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                <p className="py-20 text-white text-3xl">Conferencias</p>
                                <button className="min-w-[30%] my-20 bg-white text-black px-3 py-5 ">Ve</button>
                            </div>
                        </a>
                        <a href="/libros">
                            <div className="text-center py-10" style={{ backgroundImage: `url(${c2})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                <p className="py-20 text-white text-3xl">Libros</p>
                                <button className="min-w-[30%] my-20 bg-white text-black px-3 py-5 ">Descubre</button>
                            </div>
                        </a>
                        <a href="/adoracion">
                            <div className="text-center py-10" style={{ backgroundImage: `url(${c3})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                <p className="py-20 text-white text-3xl">Adoración</p>
                                <button className="min-w-[30%] my-20 bg-white text-black px-3 py-5 ">Disfruta</button>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}