import backgroundResources from "../assets/images/bg-formation.png";

export default function Formacion() {
    return (
        <div className={Formacion}>
            <section id='formacion' className="py-40" style={{ backgroundImage: `url(${backgroundResources})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                <div className="container mx-auto my-20">
                    <p style={{fontSize: '70px', fontWeight: 'bold', color: 'white', textAlign: 'center', fontFamily: 'Montserrat', textTransform: 'uppercase'}}>Formación</p>
                </div>
            </section>
            <div className="h-5 bg-gradient-to-r from-[#D1913C] to-[#FFD194]"></div>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex flex-col items-center justify-between lg:flex-row">
                        <div className="mb-10 lg:max-w-lg lg:pr-5 lg:mb-0">
                            <div className="max-w-xl mb-6">
                                <div>
                                    <p className="inline-block pr-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                                        Instituto La Viña Chile
                                    </p>
                                </div>
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                    Nosotros
                                </h2>
                                <p className="text-justify text-base text-gray-700 md:text-lg">
                                    La misión del Instituto Viña Chile es equipar y enseñar a quienes persiguen apasionadamente la transformación en sus esferas de influencia dadas por Dios. Nuestros estudiantes abrazan su identidad, aprenden los valores del Reino y caminan en la autoridad y el poder del Rey. Tienen hambre por la palabra de Dios y anhelan ver el reino irrumpir.
                                </p>
                            </div>
                        </div>
                        <div className="relative lg:w-1/2">
                            <img className="object-cover w-full h-56 rounded shadow-lg sm:h-96" src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260" alt=""/>
                            <a href="https://www.youtube.com/watch?v=9Sy3U8YkrhU" aria-label="Play Video" className="absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-gray-900 bg-opacity-50 group hover:bg-opacity-25">
                                <div className="flex items-center justify-center w-16 h-16 transition duration-300 transform bg-gray-100 rounded-full shadow-2xl group-hover:scale-110">
                                    <svg className="w-10 text-gray-900" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M16.53,11.152l-8-5C8.221,5.958,7.833,5.949,7.515,6.125C7.197,6.302,7,6.636,7,7v10 c0,0.364,0.197,0.698,0.515,0.875C7.667,17.958,7.833,18,8,18c0.184,0,0.368-0.051,0.53-0.152l8-5C16.822,12.665,17,12.345,17,12 S16.822,11.335,16.53,11.152z" />
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex item-center grid md:grid-cols-2 md:gap-2 sm:grid-cols-1 sm:gap-1">
                        <a href="https://institutovc.cl" className="px-10 py-10">
                            <div className="ivc-card text-center px-10 py-10">
                                <img className="py-5 mx-auto" src="https://institutovc.cl/wp-content/themes/institutovinachile/assets/img/online-video.png"/>
                                <h3 className="text-2xl my-2  py-1 text-center font-semibold">OnDemand</h3>
                                <h5 className="my-2 py-1 text-center">Desarrolla nuestros contenidos a tu propio ritmo donde sea que estés</h5>
                                <button className="my-2 py-2 px-3 rounded text-white bg-teal-400 ">¡Quiero ser parte!</button>
                            </div>
                        </a>
                        <a href="https://institutovc.cl" className="px-10 py-10">
                            <div className="ivc-card text-center px-10 py-10">
                                <img className="py-5 mx-auto" src="https://institutovc.cl/wp-content/themes/institutovinachile/assets/img/videocall.png"/>
                                <h3 className="text-2xl my-2 py-1 text-center font-semibold">Online</h3>
                                <h5 className="my-2 py-1 text-center">Sé parte de nuestras clases online vía zoom desde la comodidad de tu casa.</h5>
                                <button className="my-2 py-2 px-3 rounded text-white bg-teal-400 ">¡Quiero ser parte!</button>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}