import {useParams} from "react-router-dom";

export default function Conferencia() {

    const {slug} = useParams();

    if(slug === 'senales-maravillas-y-crecimiento-de-la-iglesia') {
        const videos = ["5dZFXcez0e8", "GxFVmGGIu2k", "-5FF2yRv-4o", "xub7fM6tVD4", "0ZBTk9Rl3kw", "YSFtWc1T6oA", "nFg0dLEjZKs", "trFfJOCjOgQ"]
        const confName = 'Señales, Maravillas y Crecimiento de la Iglesia'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                        title='YouTube video player' frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'raices-de-la-vina') {
        const videos = ["SvUzJnKM7d4", "7gN6eycm9IE", "tWu2ibn6bCo"]
        const confName = 'Raíces de la Viña'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'alba') {
        const videos = ["rFRoo3qO2J8", "bcStNJ2_gFY", "nvgyydoHtqs", "pzBvTMR7MJ4"]
        const confName = 'Alba'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'ven-espiritu-santo') {
        const videos = ["r6wcSLj269A", "wsbVzZJfKv8", "cwjenLxgQe4", "iM-x8uMpElQ", "31Ur71jc0OY"]
        const confName = 'Ven Espíritu Santo'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'siembra') {
        const videos = ["hOOpQ4U4DPQ", "LuDNJin3WuI", "pYO33xIomNo"]
        const confName = 'Siembra'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'epico') {
        const videos = ["qUxMJp3LrMA", "qm1nNl8VAcw"]
        const confName = 'Épico'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'volviendo-a-lo-esencial') {
        const videos = ["3UpSd_dyK9Y", "iFDI4V196w4", "KoZogeqntuM"]
        const confName = 'Volviendo a lo Esencial'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'ardiendo') {
        const videos = ["6_F7XwqtKa0", "4_A-TA-IR28", "_B_QHhtxnqY", "CNnUN9_d5O4"]
        const confName = 'Ardiendo'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'incontenible') {
        const videos = ["RNtpqMIW0Jo", "4oEPmfQ1no0"]
        const confName = 'Incontenible'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }

    if(slug === 'refrescante') {
        const videos = ["VuOx7z76BQE", "ZhLJ7nDiOTM", "jc_drnB3fBE"]
        const confName = 'Refrescante'
        return (
            <div className="Conferencia">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                        <div>
                            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-orange-300 uppercase rounded-full bg-teal-accent-400">
                                Conferencia
                            </p>
                        </div>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                            <span className="relative inline-block">
            <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                    id="7b568941-9ed0-4f49-85a0-5e21ca6c7ad6"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                >
                  <circle cx="1" cy="1" r=".7"/>
                </pattern>
              </defs>
              <rect
                  fill="url(#7b568941-9ed0-4f49-85a0-5e21ca6c7ad6)"
                  width="52"
                  height="24"
              />
            </svg>
          </span>{' '}
                            {confName}
                        </h2>
                    </div>
                    <div className="grid gap-5 row-gap-5 mb-8 lg:grid-cols-2 sm:grid-cols-2">
                        {
                            videos.map(video => <div className='flex flex-col h-full'><iframe width='100%' height='315' src={'https://www.youtube.com/embed/' + video}
                                                                                              title='YouTube video player' frameBorder='0'
                                                                                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                                                              allowFullScreen/></div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}